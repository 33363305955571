<template>
  <div class="wrapper">
    <Header :goodsName="goodsName" :headerData="{ name: undefined }" />
    <GoodsList :product="product" :total="total" :currentPage="currentPage" @emitChange="handleChange" />
    <Footer />
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import Header from '../../components/Header'
import GoodsList from '../../components/GoodsList'
import Footer from '../../components/Footer'
import {
  get_goods_details,
  IMAGE_URL,
  post_product_list,
  get_merchant_details,
} from '../../api'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    Header,
    Footer,
    GoodsList,
  },
  data() {
    return {
      goodsInfo: {},
      reltedData: [],
      goodsName: '',
      product: [],
      total: 0,
      currentPage: 0,
    }
  },
  mounted() {
    // this.goodsData(this.$route.query.id);
    // this.getReltedList(this.$route.query.id, this.$route.query.shopid);
    this.merchantDetailsFn()
  },
  methods: {
    handleJump(id) {
      this.$router.push({
        path: '/goods_details',
        query: { id },
      })
      this.goodsInfo = {}
      this.reltedData = []
      this.goodsName = ''
      this.goodsData(id)
      this.getReltedList(this.$route.query.id, this.$route.query.shopid)
    },
    async getReltedList(id, shop_id) {
      const {
        data: {
          data: { data },
        },
      } = await post_product_list({ shop_id, id, page: 1, limit: 4 })
      this.reltedData = data.map((item) => ({
        ...item,
        image: IMAGE_URL + item.image,
      }))
    },
    async goodsData(id) {
      const {
        data: { data },
      } = await get_goods_details({ id })
      data.image = IMAGE_URL + data.image
      data.images = data.images.split(',').map((item) => IMAGE_URL + item)
      this.goodsInfo = JSON.parse(JSON.stringify(data))
      this.goodsName = data.name
    },
    async merchantDetailsFn(page, id) {
      const {
        data: {
          data: { product },
        },
      } = await get_merchant_details({ id, page, limit: 12 })
      this.product = product.data.map((item) => ({
        ...item,
        image: IMAGE_URL + item.image,
        images: IMAGE_URL + item.images,
        shop_id: id,
      }))
      this.total = product.total
    },
    handleChange(index) {
      window.scrollTo(0, 300)
      this.currentPage = index
      this.merchantDetailsFn(index)
    },
  },
})
</script>

<style lang="less" scoped>
.wrapper {
  width: 100%;
  background: #fafafa;
}
</style>
